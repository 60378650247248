@import "../../../CoreStyle/CoreStyle";
@mixin cellSizing {
  &:nth-child(2) {
    width: 8%;
  }
  &:nth-child(3) {
    width: 6.5%;
  }
  &:nth-child(5) {
    width: 9%;
  }
  &:nth-child(10) {
    width: 9%;
  }
  &:nth-child(11) {
    width: 5%;
  }
  &:nth-child(12) {
    width: 8%;
  }
}
.SelectedUnitNumber{
  margin-right: 5px;
  // padding: 2px;
  // color: #1B5786;
  font-size: 20px;
  font-weight: bold;
}
.UnmatchedDeclarationModal {
  overflow: hidden;
  .gridContainer {
    position: relative;
    width: 100%;
    min-height: 125px;
  }
  .UnmatchedDeclarationModal[role="dialog"] {
    max-width: 1280px !important;
    max-height: 550px !important;
  }
  .UnmatchTable {
    width: 1200px;
    .ptbody {
      max-height: 360px;
      min-height: 90px;
      overflow-y: overlay;
    }
    thead.pthead {
      tr.pColumn {
        // line-height: 40px;
        th#pTh {
          @include cellSizing();

          span#columnText {
            font-size: 10px !important;
          }
        }
      }
    }
    tr.pRow {
      td#pTd {
        @include cellSizing();
        &:nth-child(12),
        &:nth-child(11) {
          text-align: right;
        }
        &:nth-child(12) {
          #cellText {
            margin-left: 0px;
            margin-right: 15px;
          }
        }
        &:nth-child(11) {
          #cellText {
            margin-left: 0px;
            margin-right: 5px;
          }
        }
        line-height: 13px;
      }
      // height: 45px !important;

      #cellText {
        font-size: 11px !important;
      }
      &:nth-child(even) {
        background: $actionLight;
        &:hover {
          background: $linkHover;
        }
      }
    }
  }
  .matchUnmatchBtn {
    float: right;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90px !important;
    height: 30px !important;
    font-size: 12px;
  }
}
