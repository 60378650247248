.JustTable-JustCanvas {
  position: sticky;
  left: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.5);
  // width: 1260px;
  height: 100px;
}
@media only screen and (max-width: 1300px) {
  .JustTable-JustCanvas {
    width: 1000px !important;
  }
}
@media only screen and (max-width: 1100px) {
  .JustTable-JustCanvas {
    width: 900px !important;
  }
}
