tr.JustStaticTable-TableRow {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-collapse: separate;

  &:hover {
    background-color: #bbddf3;
    td {
      background-color: #bbddf3;
    }
    td[data-lock="on"] {
      background-color: #bbddf3 !important;
    }
  }
}
.RowRadioButtonEnable {
  cursor: pointer !important;
}
