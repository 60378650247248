@import "../../../CoreStyle/CoreStyle.scss";
.spinBtn {
  position: relative;
  background-color: $primaryOrange;
  color: white;
  width: 160px;
  height: 40px;
  border: none;
  transition: 0.5s;
  border-radius: 2px;
  font-family: $boldFont;
  font-size: 16px;
  &:hover {
    background-color: $primaryHover;
  }
  .rotateIt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: rotate 2s linear infinite;
  }
}
button.spinBtn.disabled {
  background-color: $uiDisabled !important;
  cursor: progress !important;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
