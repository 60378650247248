div.spinDiv {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  background: #ffffff;
  opacity: 0.3;
  z-index: 1;
}
.centeredSpinner {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(0%, 0%) !important;
  margin-top: 32.5px;
  margin-left: -32.5px;
}
div.spinContainer {
  width: 100%;
  height: calc(100% - 200px);
  position: fixed;
  overflow: hidden;
  background: none;
  opacity: 0.9;
  z-index: 9999;
}
.dfdsLogo {
  position: fixed;
  left: 50%;
  top: calc(50% - 50px - 50px);
  transform: translate(-50%, -50%);
}
