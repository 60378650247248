@import "../../CoreStyle/CoreStyle";
@mixin centerIt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ForgotPasswordForm {
  @include centerIt();
  width: 600px;
//   height: 170px;
  background: white;
  //   border: 1px solid black;
  .FPFormHeader {
    // position: absolute;
    width: 100%;
    height: 50px;
    background: #eef0f1;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    user-select: none;
    border-bottom: 1px solid $groupMedium;
    font-family: $boldFont;
    .FPFormTitle {
      //   @include centerIt();

      color: $groupBlue;
    }
  }
  .FPFormArea {
    width: 100%;
    height: 60px;
    .input {
    //   margin-bottom: 10px;
    }
  }
  .FPFormFooter {
    position: absolute;
    bottom: 0;
    background: #eef0f1;
    width: 100%;
    height: 60px;
    #backLogin {
      font-size: 14px;
      cursor: pointer;
      user-select: none;
      color: $groupBlue;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      text-decoration: underline;
      text-underline-position: under;
      
    }
    .sendBtn {
      float: right;
      margin-right: 22px;
      margin-top: 10px;
    }
  }
}
