@import "../../../CoreStyle/CoreStyle";
.DeclarationHistoryModal {
    #columnText {
        font-size: 11px !important;
    }
    overflow: hidden;
    .gridContainer {
        position: relative;
        width: 100%;
        // height: 250px;
    }
    .StatusHistoryTable {
        .ptbody {
            max-height: 200px;
            min-height: 125px;
        }
        tr.pRow {
            cursor: default !important;
            // height: 45px !important;

            #cellText {
                font-size: 12px !important;
            }
            &:nth-child(even) {
                background: $actionLight;
                &:hover {
                    background: $linkHover;
                }
            }
        }
    }
}
.TsnHistoryModal__overlay {
    background-color: white !important;
}
