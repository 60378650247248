@import "../CoreStyle/CoreStyle.scss";
@mixin TableConfig {
  visibility: visible;
  display: block;
  position: relative;
}
.JustStaticTable-Container {
  .JustStaticTable-Table {
    font-family: $regularFont;
    @include TableConfig();
    text-align: left;
    position: relative;
    border-collapse: collapse;
    .JustStaticTable-Table-Header {
      @include TableConfig();
      position: sticky;
      top: 0;
      background: white;
      width: 100%;
      z-index: 1;
    }
    .JustStaticTable-Table-Rows {
      @include TableConfig();
    }
  }
}
/* Track */
::-webkit-scrollbar-track {
  background: $whiteHover;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $textMedium;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $groupBlue;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
}
