.JustNavMenu-Container {
    background-color: transparent;
    display: inline-block;
    // margin-left: 10px;
    padding: 30px;
    // border: 1px solid white;
    cursor: pointer;
    #JustNavMenu-Text {
        color: white;
        user-select: none;
    }
}
.NotActive-JustNavMenu {
    #JustNavMenu-Text {
        color: gray;
    }
}
