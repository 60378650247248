@import "../../../CoreStyle/CoreStyle.scss";
.dotBtn {
    position: relative;
    background-color: $primaryOrange;
    color: white;
    width: 250px;
    height: 40px;
    border: none;
    transition: 0.5s;
    font-family: $boldFont;
    font-size: 18px;
    .dotBtn:hover {
        background-color: $primaryHover;
    }
    button.disabled {
        background-color: $primaryHover !important;
        cursor: progress !important;
    }
}
.dotBtn.disabled {
    background-color: rgba(77, 78, 76, 0.3) !important;
    cursor: not-allowed !important;
}
.dotBtn span:nth-child(1) {
    opacity: 0;
    animation: opacityAnimFirst 0.75s linear infinite;
}

.dotBtn span:nth-child(2) {
    opacity: 0;
    animation: opacityAnimSecond 0.75s linear infinite;
}

.dotBtn span:nth-child(3) {
    opacity: 0;
    animation: opacityAnimLast 0.75s linear infinite;
}
@keyframes opacityAnimFirst {
    33% {
        opacity: 1;
    }
}
@keyframes opacityAnimSecond {
    66% {
        opacity: 1;
    }
}
@keyframes opacityAnimLast {
    100% {
        opacity: 1;
    }
}
