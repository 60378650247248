@import "../../../CoreStyle/CoreStyle.scss";
th.JustStaticTable-HeaderCell {
  text-align: inherit;
  height: 40px;
  // cursor: pointer;
  border-right: 1px solid rgba(255, 255, 255, 0.15);

  #HeaderCell-Text {
    line-height: 14px;
    font-size: 12px;
    // font-family: $boldFont;
    color: white;
    margin-left: 5px;
  }
  .HeaderCell-Dialog {
    display: block;
    height: 200px;
    width: 200px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: #ccc;
  }
}
