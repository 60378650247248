@import "../../CoreStyle/CoreStyle";
@mixin centerIt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.T-Warning{
  background: #FF0000 !important;
}
.ResetPasswordForm {
  @include centerIt();
  width: 600px;
  background: white;
  .RPFormHeader {
    width: 100%;
    height: 50px;
    background: #eef0f1;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    user-select: none;
    border-bottom: 1px solid $groupMedium;
    font-family: $boldFont;
    .RPFormTitle {

      color: $groupBlue;
    }
  }
  .RPFormArea {
    width: 100%;
    height: 120px;
    .input {
    }
  }
  .RPFormFooter {
    position: absolute;
    bottom: 0;
    background: #eef0f1;
    width: 100%;
    height: 60px;
    .resetBtn {
      float: right;
      margin-right: 22px;
      margin-top: 10px;
    }
  }
}
