.input{
    width: 520px;
    position: relative;
    border-radius: 5px;
    margin-left: 55px;
    margin-top: 10px;
}
.input input{
    width: 100%;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.input .imgCont{
    position: absolute;
    width: 48px;
    height: 50px;
    top: 0px;
    left: -45px;
    background: #F7F7F7;
    border: 1px solid #b8b8b7;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

}
.input .myIcon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.Invalid #myInput{
    border: 1px solid #BE1E2D;
}

input:focus {
    outline: none !important;
    border:1px solid green;
    box-shadow: 0 0 5px #719ECE;
}
.errorMessageP{
    margin: 0;
    padding: 0;
    margin-left: 55px;
    color:#be1e2d;
}
.-nomargin{
    margin: 0 !important;
}