.Cell-DataType-Number {
  float: right;
  margin-right: 5px;
}
#JustTable-RowCell-Icon {
  display: block;
  text-align: center;

  svg {
    margin-right: auto;
    margin-left: auto;
  }
}

#JustTable-RowCell-Data {
  margin-left: 5px;
  -webkit-box-decoration-break: clone;
  line-height: 15px;
  box-decoration-break: clone;
  &:hover{
    svg{
      fill: rgba(0, 0, 0, 1) !important;
      color: rgba(0, 0, 0, 1) !important;
    }
  }
}
@mixin centerOfElement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#JustTable-IconContainer {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;

  #JustTable-RowLocked-Updatable {
    margin-right: auto;
    margin-left: auto;
    fill: rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);
    // @include centerOfElement;
  }
  #JustTable-RowUnlocked-Updatable {
    // display: block;
    // text-align: center;
    margin-right: auto;
    margin-left: auto;
    fill: rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);
    // @include centerOfElement;
  }
}
