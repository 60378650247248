.JustTooltip-Container {
  min-width: 100px;
  max-width: 250px;
  min-height: 30px;
  border-radius: 5px;
  position: absolute;
  z-index: 999;
  background-color: white;
  opacity: 0.90;
  outline: none;
  text-align: center;
  // background-color: rgba($color: , $alpha: 1.0);
  .JustTooltip-Context {
    width: 100%;
    height: 100%;
    padding: 5px;
    #JustTooltip-Text {
      color: rgb(82, 76, 76);
      font-size: 12px;
    }
  }
  .JustTooltip-Edge {
    width: 10px;
    height: 10px;
    background-color: white;
    position: absolute;
    bottom: -5px;
    left: 50%;
    // transform: ;
    transform: rotateZ(45deg) translateX(-50%);
  }
}
