$backgroundGrey : #eef0f1;
$whiteHover : #f5f6f7;
$textMedium : #b8b8b7;
$textLight : #e4e5e4;
$textGrey : #4d4e4c;
$groupBlue : #002b45;
$groupMedium : #99aab5;
$groupLight : #e8f4fb;
$linkBlue : #1b5786;
$linkHover : #5087ac;
$actionBlue : #68b2e3;
$actionHover : #5087ac;
$actionMedium : #c3e0f4;
$primaryOrange : #ed8b00;
$primaryHover : #cc6600;
$uiEnabled : #b8b8b7;
$uiDisabled : #e4e5e4;
$yesGreen : #5aa63b;
$cautionAmber : #ffc108;
$noRed : #be1e2d;
$actionLight : #eaf4fb;

