@mixin triangleColor {
  color: rgba(255, 255, 255, 0.5);
  fill: rgba(255, 255, 255, 0.5);
  //   &:hover {
  //     color: rgba(255, 255, 255, 1);
  //     fill: rgba(255, 255, 255, 1);
  //   }
}
@mixin triangSize {
  width: 15px;
  height: 15px;
  display: block;
  padding: 0;
  margin: 0;
}
.JustSorter-Container {
  display: inline-block;
  float: right;
  user-select: none;
  cursor: pointer;
  height: 25px;
  margin-right: 1px;
  .JustSorter-TriangleUp {
    @include triangSize;
    @include triangleColor;
  }
  .JustSorter-TriangleDown {
    @include triangSize;
    @include triangleColor;
  }
  .JustSorter-Sorted {
    color: #ed8800;
    fill: #ed8800;
  }
}
