.searchLogo{
    position: absolute;
    left: 50%;
    top: calc(50% - 25px);
    transform: translate(-50%,-50%);
}
.messageSpan{
    position: absolute;
    left: 50%;
    top: calc(50% + 25px);
    transform: translate(-50%,-50%);
    font-size: 20px;
    font-weight: bold;
    color: #002b45;
}
.minSizeDiv{
    width: 100%;
    height: 200px;
}
