.DeclarationsPage-Container {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(86vw - 15px);
    height: calc(100vh - 70px - 100px);
    max-width: 1500px;
    overflow: hidden;
}

