.JustCanvas-WaitForRequest {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .WaitForRequest-Icon {
    display: block;
    svg {
      width: 32px;
      height: 32px;
    }
    margin-left: auto;
    margin-right: auto;
  }
  span#WaitForRequest-Text {
    font-size: 24px;
  }
}
