@import "../../../../CoreStyle/CoreStyle";
.YesNoModal{
  #YesNoMessage {
    margin: 0 !important;
    padding: 0 !important;
  }
  .YesButton {
    float: left;
    background: $yesGreen;
    width: 100px;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .NoButton {
    float: right;
    background: $noRed;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    // margin-bottom: 10px;
  }
}
.YesNoModal[role=dialog]
{
    min-width: 450px;
    min-height: 150px;
}