@import "../../CoreStyle/CoreStyle";
@mixin textWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin textNoWrap {
  overflow: inherit;
  white-space: normal;
  text-overflow: inherit;
}
@mixin tableCellSizeRemoveCheckBox {
  &:nth-child(2) {
    width: 10% !important;
  }
  &:nth-child(3) {
    width: 7% !important; 
  }
  &:nth-child(4) {
    width: 11% !important;
  }
  &:nth-child(5) {
    width: 12% !important;
  }
  &:nth-child(6) {
    width: 12% !important;
  }
  &:nth-child(7) {
    width: 12% !important;
  }
  &:nth-child(8){
    width: 12% !important;
  }
  &:nth-child(9) {
    width: 8% !important;
  }
  &:nth-child(10) {
    width: 8% !important;
  }
  &:nth-child(11) {
    width: 7.5%  !important;
  }
  &:nth-child(12) {
    width: 7%  !important;
  }
}
@mixin tableCellSize {
  &:nth-child(1) {
    width: 7%;
  }
  &:nth-child(2) {
    width: 3%;
  }
  &:nth-child(3) {
    width: 7%;
  }
  &:nth-child(4) {
    width: 8%;
  }
  &:nth-child(5) {
    width: 8%;
  }
  &:nth-child(6) {
    width: 8%;
  }
  &:nth-child(7) {
    width: 8%;
  }
  &:nth-child(8){
    width: 4%
  }
  &:nth-child(9) {
    width: 4%;
  }
  &:nth-child(10) {
    width: 3.5%;
  }
  &:nth-child(11) {
    width: 3%;
  }
  &:nth-child(12) {
    width: 10%;
  }
}

@mixin subTableCellSize {
  &:nth-child(1) {
    width: 8%;
  }
  &:nth-child(2) {
    width: 9%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 8%;
  }
  &:nth-child(5) {
    width: 8%;
  }
  &:nth-child(6) {
    width: 9%;
  }
  &:nth-child(7) {
    width: 10%;
  }
  &:nth-child(8) {
    width: 10%;
  }
  &:nth-child(9) {
    width: 10%;
  }
  &:nth-child(10) {
    width: 5%;
  }
  &:nth-child(11) {
    width: 6%;
  }
  &:nth-child(12) {
    width: 3%;
  }
}
h4#voyageInfo {
  font-size: 20px;
  display: inline-block;
  margin: 0;
  line-height: 45px !important;
}
.matchSelect {
  display: inline-block !important;
  float: right !important;
  margin-right: 140px !important;
  width: 155px !important;
  height: 38px !important;
  margin-top: 4px;
  outline: none !important;
  outline-offset: none !important;
  font-size: 12px !important;

  &:focus {
    outline: none !important;
    outline-offset: none !important;
  }
}
.bookingPage {
  position: absolute;
  overflow: hidden;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(86vw - 15px);
  height: calc(100vh - 40px - 100px);
  max-width: 1500px;
}
.BookingTable {
  #pTh {
    &:first-child {
      .filterPopup {
        right: -21px !important;
      }
    }
  }
}

.bookingTableContainer {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100vh - 40px - 100px - 50px);
  overflow: hidden;
  .bookingTableHeader {
    width: 100%;
    position: relative;
    height: 40px;
    margin-bottom: 10px;

    .title_booking {
      position: absolute;
      width: calc(86vw - 15px);
      left: 50%;
      transform: translateX(-50%);
      top: calc(70px + 50px);
    }
    .refreshBtn {
      position: absolute;
      right: 45px;
      top: 0px;
      width: 50px !important;
      height: 38px !important;
      margin-top: 4px;
      font-size: 12px !important;
      path {
        fill: $yesGreen !important;
      }
      cursor: pointer;
    }
    .DeleteButton{
      position: absolute;
      right: 5px;
      top: 0px;
      width: 50px !important;
      height: 38px !important;
      margin-top: 4px;
      font-size: 12px !important;
      path {
        fill: $yesGreen !important;
      }
      cursor: pointer;
    }
    .ExcelButton {
      position: absolute;
      right: 85px;
      top: 0px;
      fill: $yesGreen;
      width: 50px !important;
      height: 38px !important;
      margin-top: 4px;
      font-size: 12px !important;
      cursor: pointer;
      background: transparent;
    }
  }
  .bookingTableContent {
    overflow-x: auto !important;
    overflow-y: hidden;
    // height: calc(100vh - 40px - 100px - 50px - 40px);
  }
  .bookingTableContent .PerfectTable {
    min-width: 1250px !important;
    min-height: 50px !important;
    line-height: 14px;

    #pSubTd {
      @include subTableCellSize();
      font-size: 11px !important;
      &:nth-child(2) {
        cursor: pointer;
        span#cellText {
          text-decoration: underline !important;
          text-underline-position: under !important;
        }
      }
      &:nth-child(11),
      &:nth-child(10) {
        text-align: right;
        span#cellText {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
    #pSubTh {
      @include subTableCellSize();

      font-size: 10px !important;
    }
    tr.pColumn > #pTh {
      @include tableCellSize();
    }
    tr.pColumn > #pTh.withcheck {
      @include tableCellSizeRemoveCheckBox();
    }
    tr:hover svg path:nth-child(2) {
    }
    #pTd {
      @include tableCellSize();
      &:nth-child(8),
      &:nth-child(9) {
        text-align: right;
        span#cellText {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
    #pTd.withcheck {
      @include tableCellSizeRemoveCheckBox();
      &:nth-child(8),
      &:nth-child(9) {
        text-align: right;
        span#cellText {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }
  .bookingTableContent.OverFlowShow .PerfectTable {
    @include textNoWrap();
    min-width: 1550px !important;
    min-height: 150px;
    > label {
      display: inline-block;
    }
    #pTd {
      @include tableCellSize();
    }
    #pTh {
      @include tableCellSize();
    }
    #pTd.withcheck {
      @include tableCellSizeRemoveCheckBox();
    }
    #pTh.withcheck {
      @include tableCellSizeRemoveCheckBox();
    }
  }
}
.breadcrumb {
  padding: 0.75em 0em !important;
  position: absolute;
  left: 0;
  top: 0;
  background: none !important;
  .breadcrumb-item a {
    color: #1b5786;
  }
  .breadcrumb-item a:hover {
    color: #5087ac;
  }
  > li + li:before {
    color: #ccc;
    content: "> " !important;
    padding: 0 5px;
  }
}
.matchButton {
  width: 40px !important;
  height: 25px !important;
  font-size: 9px !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  color: white !important;
  background-color: $primaryOrange !important;
  opacity: 0;
  span {
    padding: 0 !important;
    margin: 0 !important;
  }
}
.pRow {
  &:hover {
    .matchButton {
      opacity: 1;
    }
  }
}
.pRow.tableActive {
  .matchButton {
    opacity: 1;
  }
}
.subTableAnim {
  animation: fadeInFromNone 0.25s ease-out;
}
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
.trueClass {
  color: $yesGreen;
  font-family: $boldFont;
}
.falseClass {
  color: $noRed;
  font-family: $boldFont;
}
.dangerColour {
  color: $primaryOrange !important;
  fill: $primaryOrange !important;
}
.DeleteRowButton {
  cursor: pointer;
  font-size: 9px;
  opacity: 0;
  width: 45px;
  height: 20px;
  padding: 0;
  background: $noRed;
  transition: 0.1s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pSubRow {
  &:hover {
    .DeleteRowButton {
      transition: 0.1s;
      opacity: 1;
      background: $noRed;
    }
  }
}
.SimpleGrid-Container {
  width: 1020px;
  position: absolute;
  top: 5px;
  left: 170px;
  background: none !important;
  .SimpleGrid-GridItem {
    background: none !important;
    height: 36px !important;
    p#SimpleGrid-ColumnName {
      font-size: 10px;
      color: black !important;
      text-decoration: underline;
      text-underline-position: under !important;
    }
    p#SimpleGrid-Data {
      font-size: 14px;
      color: black !important;
      text-align: center;
      vertical-align: middle;
      line-height: 40px;
    }
    &:hover {
    }
  }
}
.BookingTable {
  #pTh > span#columnText {
    font-size: 11px !important;
  }
  #pTd > span#cellText {
    font-size: 12px !important;
  }
  .ptbody {
    max-height: 350px;
    min-height: 60px;
  }
}
.PosMiddle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.excelBtn {
  background: $yesGreen !important;
  width: 120px !important;
  height: 38px !important;
  font-size: 12px !important;
  padding: 0 !important;
  position: fixed;
  right: 0;

  margin-top: 20px !important;
  margin-bottom: 10px;
  outline: none !important;

  cursor: pointer;
  > span {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    overflow: visible !important;
  }
  &:focus {
    outline: none !important;
  }
  &:hover {
    fill: green;
  }
}
.BookingTable {
  .ptbody {
    max-height: calc(100vh - 40px - 100px - 50px - 40px - 50px - 10px);
  }
}

@media only screen and (max-width: 1720px) {
  .SimpleGrid-Container {
    width: 920px;
    .SimpleGrid-GridItem {
      p#SimpleGrid-Data {
        font-size: 14px !important;
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .SimpleGrid-Container {
    width: 870px;
    .SimpleGrid-GridItem {
      p#SimpleGrid-Data {
        font-size: 13px !important;
      }
    }
  }
}
@media only screen and (max-width: 1550px) {
  .SimpleGrid-Container {
    width: 820px;
    .SimpleGrid-GridItem {
      p#SimpleGrid-Data {
        font-size: 13px !important;
      }
    }
  }
}
@media only screen and (max-width: 1500px) {
  .SimpleGrid-Container {
    width: 770px;
    .SimpleGrid-GridItem {
      p#SimpleGrid-Data {
        font-size: 12px !important;
      }
    }
  }
}
@media only screen and (max-width: 1430px) {
  .SimpleGrid-Container {
    width: 720px;
    .SimpleGrid-GridItem {
      p#SimpleGrid-Data {
        font-size: 12px !important;
      }
    }
  }
}
@media only screen and (max-width: 1370px) {
  .SimpleGrid-Container {
    width: 670px;
    .SimpleGrid-GridItem {
      p#SimpleGrid-Data {
        font-size: 11px !important;
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .SimpleGrid-Container {
    width: 570px;
    .SimpleGrid-GridItem {
      p#SimpleGrid-Data {
        font-size: 9px !important;
      }
    }
  }
}
@media only screen and (min-height: 1200px) {
}


@media only screen and (max-width: 1150px) {
  .SimpleGrid-Container {
    width: 470px;
    .SimpleGrid-GridItem {
      p#SimpleGrid-Data {
        font-size: 9px !important;
        color: $groupBlue;
      }
    }
  }
}

.controlGreen {
  fill: $yesGreen;
  margin-right: 5px;
}
.controlRed {
  fill: $noRed;
  margin-right: 5px;
}
.controlOrange {
  fill: $primaryOrange;
  margin-right: 5px;
}
.expOfColours {
  p {
    display: inline-block;
    margin: 0;
    padding: 0;
    // -webkit-box-decoration-break: clone;
  }
}
