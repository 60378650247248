.IdentityUser-Modal-Container {
    .IdentityUser-Modal-Content {
        height: 300px;
        width: 100%;
        .RightContent-Container {
            display: inline-block;
            width: calc(50% - 2px);
            height: 100%;
        }
        .LeftContent-Container {
            display: inline-block;
            width: calc(50% - 2px);
            height: 100%;
        }
    }

    .InputWithLabel {
        display: block;
        margin-left: 5px;
        margin-bottom: 10px;
        width: 250px;
        height: 60px;
        .Input-Label {
            color: #002b45;
            // font: $boldFont !important;
            font-weight: bold;
            line-height: 1.25;
            font-size: 12px;
            margin-bottom: 4px;
            display: block;
        }
        input {
            font-size: 14px;
            &:disabled {
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
    .IdentityUser-Modal-Footer {
        width: calc(100% - 5px);
        position: absolute;
        left: 5px;
        bottom: 5px;
        .IdentityUser-Button-Save {
            float: right;
            margin-right: 10px;
        }
    }
}

.IdentityUser-Modal {
    div[role="dialog"] {
        min-width: 600px;
        max-width: 600px;
        min-height: 450px;
        max-height: 450px;
    }
}
