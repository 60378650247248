@import "../../../CoreStyle/CoreStyle.scss";
.DeclarationHeader-Container {
    width: 100%;
    margin-top: 50px;
    height: 65px;
    margin-bottom: 5px;
    position: relative;
    // background-color: red;
    .datePickerContainer {
        width: 500px;
        display: inline-block;
        .datepicker_container {
            width: 250px !important;
            .dateInputs {
                text-decoration: underline;
                text-underline-position: under;
                text-decoration-color: #002b45;
                height: 50px !important;
            }
        }
    }
    .DayPicker {
        left: 0 !important;
        width: 744px;
    }
    .TextField-Container {
        position: absolute;
        left: 0px;
        top: -6px;
        .DateField-Wrapper {
            display: inline-block;
            width: 200px;
            position: relative;
            height: 60px;
            margin-left: 10px;
            #declarationDate-Label {
                width: 100%;
                font-size: 12px;
                position: absolute;
                top: -5px;
                left: 0;
            }
            > div {
                margin-top: 10px;
                width: 200px;
                height: 40px;
            }
            input {
                width: 200px;
                height: 40px;
                font-size: 14px;
                padding-left: 10px;
                font-family: $regularFont;
                &::placeholder {
                    color: rgba(77, 78, 76, 0.2);
                }
            }
            input {
                &:focus {
                    outline: none !important;
                    border: 1.5px solid #68b2e3;
                    box-shadow: 0 0 5px 0px #68b2e3;
                }
            }
        }
        .TextField-Wrapper {
            display: inline-block;
            width: 200px;
            height: 60px;
            margin-left: 10px;

            // height: 50px;
            div {
                margin: 0;
                input {
                    font-size: 14px !important;
                }
            }
            label {
                font-size: 12px !important;
                margin-bottom: 1px !important;
            }
        }
    }
    .searchBtn {
        vertical-align: top !important;
        width: 200px !important;
        height: 50px !important;
        margin-left: 15px !important;
        float: right !important;
    }
}
@media only screen and (max-width: 1230px) {
    .dotBtn.searchBtn {
        width: 100px !important;
    }
}
@media only screen and (max-width: 1450px) {
    .dotBtn.searchBtn {
        width: 150px !important;
    }
}
