@font-face {
  font-display: swap;
  font-family: DFDS-REGULAR;
  src: url('./DFDS-Regular.woff2') format('woff2'),
    url('./DFDS-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: DFDS-BOLD;
  src: url('./DFDS-Bold.woff2') format('woff2'),
    url('./DFDS-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: DFDS-LIGHT;
  src: url('./DFDS-Light.woff2') format('woff2'),
    url('./DFDS-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: DFDS-ITALIC;
  src: url('./DFDS-Italic.woff2') format('woff2'),
    url('./DFDS-Italic.woff') format('woff');
  font-style: italic;
}
