.DeclarationTable-Container {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    height: calc(100% - 125px);
    .PerfectTable {
        position: relative;
        height: calc(100% - 0px);
        line-height: 14px;
        min-width: 1500px;
        .Row-HistoryButton {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 25px !important;
        }
        .pRow {
            cursor: default !important;
        }
        .ptbody {
            max-height: calc(100% - 50px);
            overflow: overlay !important;
        }
        td:nth-child(1), //SERVICE PROVIDER NAME
        th:nth-child(1) {
            width: 10%;
        }
        td:nth-child(2), //DECLARATION STATUS
        th:nth-child(2) {
            width: 6%;
        }
        td:nth-child(3), //TSN
        th:nth-child(3) {
            width: 9%;
        }
        td:nth-child(4), //DECLARATION DATE
        th:nth-child(4) {
            width: 7%;
        }
        td:nth-child(5), //UNIT NUMBER
        th:nth-child(5) {
            width: 7%;
        }
        td:nth-child(6),//TNP
        th:nth-child(6) {
            width: 4%;
        }
        td:nth-child(7), //TNW
        th:nth-child(7) {
            width: 4%;
        }

        td:nth-child(8), //VOYAGE NUMBER 
        th:nth-child(8) {
            width: 7%;
        }

        td:nth-child(9), //DEPARTURE DATE
        th:nth-child(9) {
            width: 7%;
        }
        td:nth-child(10), //RELEASE NUMBER
        th:nth-child(10) {
            width: 7%;
        }

        td:nth-child(6), //TNP, TNW
        td:nth-child(7) {
            text-align: right;
            span#cellText {
                margin-left: 0;
                margin-right: 10px;
            }
        }

        td:nth-child(2) {
            cursor: pointer;
            #Row-History {
                margin-left: 5px;
                font-size: 14px;
                text-decoration: underline !important;
                text-underline-position: under !important;
            }
        }
    }
}
.Row-HistoryButton {
    width: 60px !important;
    height: 25px !important;
    font-size: 11px !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    color: white !important;
    // background-color: $primaryOrange !important;
    opacity: 0;
    span {
        padding: 0 !important;
        margin: 0 !important;
    }
}
.pRow {
    &:hover {
        .Row-HistoryButton {
            opacity: 1;
        }
    }
}
.pRow.tableActive {
    .Row-HistoryButton {
        opacity: 1;
    }
}
