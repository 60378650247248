.ListElement-JustList{
    margin-left: 10px;
    padding: 2px;
    color: white;
    background-color: #1B5786;
    font-size: 14px;
    font-weight: 200;
}
.ListElement-ShowMoreButton{
    margin-left: 15px;
    padding: 2px;
    color: white;
    background-color: #1B5786;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
}
.ListElement-JustList.in-modal-list{
    margin-top: 5px;
    display: inline-block !important;
}