.IdentityUser-Container {
    width: 100%;
    height: 100%;
    #HeaderText {
    }
    .IdentityUser-Content-Header {
        position: relative;
        width: 100%;
        height: 50px;
        .IdentitiyUser-AddUser {
            position: absolute;
            right: 0;
        }
    }
    .IdentityUser-Content-Container {
        .IdentityUser-Table-Container {
            height: 550px;
            overflow-y: auto;
            .JustTable-RowCell[data-field="actionContext"] {
                background-color: white;
            }
            .JustTable-Container {
                td,
                th {
                    &:nth-child(7) {
                        width: 5%;
                    }
                }
            }
        }
    }
}
