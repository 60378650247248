td.JustStaticTable-RowCell {
  height: 40px;
  line-height: 15px;
  font-size: 15px;
  color: #4d4e4c;
  word-break: break-word;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
td[data-lock="on"] {
  background-color: #eef0f1;
  // border-bottom: 1px solid black;
}
.rowRadioButton {
  width: 20px;
  height: 10px;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  display: block;
  text-align: center;
}
