.JustTable-ContextMenuButton-Container {
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  display: block;
  text-align: center;
  #JustTable-ContextMenuButton {
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    display: block;
    text-align: center;
    cursor: pointer;
  }
}
