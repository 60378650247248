@import "CoreStyle/CoreStyle";
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: $regularFont;
  overflow-x: hidden;
  overflow-y: auto;

  background-size: cover;

  .layer {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .contentContainer {
    height: 100vh;
    width: 100vw;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $whiteHover;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $textMedium;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $groupBlue;
}
button{
  background: transparent;
}