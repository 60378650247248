.SimpleGrid-Container {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto ;
//   grid-gap: 1px;
  background-color: white  ;
  text-align: center;

  .SimpleGrid-GridItem {
    background-color: #1b5786;
    height: 40px;
  }
  #title{
    // font-weight: bold;;
  }
  
  p{
      margin: 0;
      padding: 0;
      color: #e4e5e4;
  }
}
