@import "../../../CoreStyle/CoreStyle";
$filterWidth: 180px;
$filterHeight: 60px;
$offSetMouseX: 30px;
$offSetMouseY: 30px;

@mixin textWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin tableLayout {
  display: table;
  width: 100%;
  table-layout: fixed;
}
@mixin infoIcon {
  .infoIcon {
    width: 14px;
    height: 14px;
    display: inline;
    margin-left: 10px;
    cursor: help;
    color: $actionBlue;
    overflow: visible;
    z-index: 3 !important;
    &:after,
    &:before {
      content: "";
      z-index: 2;
    }
    path {
      &:after,
      &:before {
        content: "";
        z-index: 2;
      }
    }
  }
}
@mixin filterCss {
  .filterIcon {
    position: relative;
    float: right;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    // margin-top: 5px;
    color: $actionBlue;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
  .filterIcon.filterNotPossible {
    color: $actionBlue !important;
    cursor: not-allowed !important;
    &:hover {
      color: $actionBlue !important;
      cursor: not-allowed !important;
    }
  }
  .filterIcon.active {
    color: $cautionAmber;
    &:hover {
      color: white;
      cursor: pointer !important;
    }
  }
  .filterMouseArea {
    display: inline-block;
    position: absolute;
    right: 15px - $offSetMouseX;
    top: 38px - $offSetMouseY;
    width: $filterWidth + $offSetMouseX * 2;
    height: $filterHeight + $offSetMouseY * 2;
    z-index: 2;
  }
  .filterPopup {
    position: absolute;
    right: $offSetMouseX;
    top: $offSetMouseY;
    background: white;
    border: 1px black solid;
    width: $filterWidth;
    height: $filterHeight;
    opacity: 0.95;
    .filterTextInputLabel {
      display: inline-block;
      font-size: 10px;
      font-weight: normal;
      position: absolute;
      top: 5px;
      left: 10px;
      color: black;
    }
    .filterTextInput {
      width: $filterWidth - 25px;
      height: $filterHeight / 2 - 5px;
      margin-top: ($filterHeight / 2) / 2 + 6px;
      background: $actionLight;
      margin-left: 10px;
      font-size: 12px;
      border: none;
      text-indent: 5px;
      // border-bottom: 1px solid $groupMedium;
      outline: none !important;
      transition: 0.25s;
      box-shadow: none;
      border-radius: 4px;
      color: black;
      // direction:;
      &:focus {
        // border-bottom: 1px solid $linkHover;
      }
    }
    .clearFilterTextIconCont {
      display: inline-block;
      top: 21px;
      right: 10px;
      position: absolute;
      height: $filterHeight / 2 - 5px;
      width: 15px;
      // background: $actionLight !important;
    }
    .clearFilterTextIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      fill: $textGrey;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    .filterTextInput.active {
      // border-bottom: 1px solid $yesGreen !important;
    }
  }
  #infoText {
    width: 100%;
    height: 100%;
    font-size: 11px;
    font-weight: normal;
    /**/
  }
  // [data-id=tooltip]{
  //   width : 150px;
  // }
}
@mixin configIcon {
  width: 15px;
  height: 15px;
  // margin-left: 10px;
  // margin-right: 10px;
}
@mixin controlCss {
  .controlIconFalse {
    @include configIcon();
    color: $noRed !important;
    fill: $noRed;
  }
  .controlIconFalseColumn {
    @include configIcon();
    width: 15px !important;
    height: 15px !important;
    color: $noRed !important;
    margin-bottom: 3px !important;
  }
  .controlIconTrue {
    @include configIcon();
    color: $yesGreen;
  }
  .controlIconText {
    font-size: 12px;
    font-family: $boldFont;
    margin-left: 5px;
    color: $yesGreen;
  }
  .textRuler {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    display: inline-block;
  }
}
@mixin filterNotFound {
  .FilterNotFound {
    width: 100%;
    height: 200px;
    position: relative;
    .FilterNotFoundIcon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $groupBlue;
    }
    .FilterNotFoundText {
      position: absolute;
      top: calc(50% + 50px);
      left: 50%;
      font-size: 20px;
      font-family: $boldFont;
      transform: translate(-50%, -50%);
      color: $groupBlue;
    }
  }
}
@mixin tBodySizing {
  display: block;
  overflow-y: auto;
  // height: calc(100% - 50px);
  // min-height: 200px;
}
@mixin rowCellIcon {
  .columnCellIcon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }
}
@mixin subTableCellIcon {
  .columnCellIcon {
    width: 18px;
    height: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 2px;
    margin-left: 2px;
  }
}
.PerfectTable {
  line-height: 20px;

  display: block;
  height: calc(100% - 0px);
  // user-select: none;
  .PERFECTTABLE-NOT-VALID-ROW {
    color: rgba(77, 78, 76, 0.671);
    svg {
      color: black;
    }
  }
  .pthead {
    @include tableLayout();
    tr.pColumn {
      background: $groupBlue;
      color: $textLight;
      height: 40px;
      span#columnText {
        font-family: $regularFont;
        font-size: 12px;
        font-weight: normal;
        margin-left: 5px;
      }
      #pTh {
        position: relative;
        border-right: 1px solid rgba(232, 244, 251, 0.25);

        @include infoIcon();
        @include filterCss();
      }
    }
  }
  .ptbody {
    td {
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      word-break: break-word;
      // border-bottom: 1px solid $uiEnabled;
      // border-right: 1px solid $uiEnabled;
      &:last-child {
        border-right: none;
      }
    }
    position: relative;
    tr.filteredRow {
      display: none !important;
    }
    @include tBodySizing();
    // min-height: 200px;
    @include filterNotFound();
    tr.pRow {
      height: 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      position: relative;
      .tableRulerLine {
        position: absolute;
        top: 52px;
        width: 3px;
        left: 5px;
        height: 125px;
        background: $textGrey;
        .rowRulerLine {
          position: absolute;
          height: 2px;
          width: 35px;
          background: $textGrey;
        }
      }
      @include tableLayout();
      #pTd {
        // @include textWrap();
        position: relative;
      }
      @include controlCss();
      @include rowCellIcon();
      &:hover {
        background: $linkHover;

        cursor: pointer;
        color: $textLight;
        svg.columnCellIcon path {
          fill: white !important;
        }
        .controlIconText {
          color: white !important;
        }
      }
      span#cellText {
        margin-left: 5px;
        word-wrap: break-word;

        font-size: 14px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }
    }
  }
  .pRow.tableActive {
    background: $actionMedium;
    color: black;
    &:hover {
      + .PerfectSubTable {
        box-shadow: 0px 0px 0px 10px $linkHover !important;
      }
    }
  }
  .pRow.SelectedRow {
    color: $whiteHover;
    background: $actionBlue;
  }
}
//--------------------------------------- SUB TABLE CSS ---------------------------------------
.PerfectSubTable {
  margin-top: 10px;
  // user-select: none;
  width: calc(100% - 10px - 10px) !important;

  box-sizing: border-box;
  border: 1px solid $actionBlue;
  box-shadow: 0px 0px 0px 10px #c3e0f4;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  .pSubthead {
    background: $uiDisabled;
    color: black;
    //margin-top: 1px;
    //border-left: 5px solid $actionMedium;
    @include tableLayout();
    .pSubColumn {
      height: 40px;
      font-size: 11px;

      th#pSubTh {
        border-left: 1px solid white;
        font-weight: normal !important;
        position: relative;

        .filterIcon {
          margin-top: 10px !important;
          color: $textGrey !important;
        }
        .filterIcon.active {
          color: $uiEnabled !important;
        }
        span#columnText {
          margin-left: 5px;
          // line-height: 40px;
        }
        @include infoIcon();
        .infoIcon {
          color: black;
        }
        @include filterCss();
      }
    }
  }
  .pSubtbody {
    tr.filteredRow {
      display: none !important;
    }

    @include tBodySizing();
    @include filterNotFound();
    .pSubRow {
      // margin-top: 1px;
      // margin-bottom: 1px;
      //border-left: 3px solid $textGrey;
      height: 40px;
      // border-right: 4px solid $linkHover;

      // border-bottom: 2px solid $linkHover;
      //border-top: 2px solid $linkHover;
      // border-width: 3px;
      // border-style: solid;
      // border-image: linear-gradient(to left, #00C853, #B2FF59);
      font-size: 13px;
      &:nth-child(odd) {
      }
      &:nth-child(even) {
        background: $actionLight;
      }
      span#cellText {
        margin-left: 5px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }
      &:hover {
        // font-size: 14px;
        border-left-color: $linkBlue;
        // border-bottom-color: $linkBlue;
        // border-right-color: $linkBlue;
        background: $actionHover;
        color: $whiteHover;
      }
      @include tableLayout();
      #pSubTd {
        position: relative;
        // @include textWrap();
        @include subTableCellIcon();
      }
      @include controlCss();
    }
  }
}
//--------------------------------------- SUB TABLE CSS ---------------------------------------

span#finalResulText {
  font-family: $boldFont;
  font-size: 11px;
}

.CheckTh {
  position: relative;
  width: 25px;
  // border-right: 1px solid white;
}

.PerfectTableCheckbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none !important;
  box-shadow: none !important;
}
.CheckTd {
  position: relative;
  width: 25px;
}
.pRow {
  &:nth-child(even) {
    // .CheckTd {border-right: 1px solid white;}
  }
  &:nth-child(odd) {
    // .CheckTd {border-right: 1px solid $actionLight;}
  }
}
.rowChecked {
  background: $actionBlue !important;
  color: $textLight !important;
  // margin-top: 1px;
  // box-shadow: $whiteHover 0px 0px 0px 1px
  &:hover {
    background: $linkBlue !important;
    // color: black !important;
  }
}
