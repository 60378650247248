.JustCanvas-NotFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .NotFound-Icon {
    display: block;
    width: 32px;
    height: 32px;
    margin-left: auto;
    margin-right: auto;
  }
  span#NotFound-Text {
      font-size: 24px;
  }
}
